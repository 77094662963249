import { Button } from "@/components/ui/button";
import coinTelegraph from "@/assets/featured/coinTelegraph.svg";
import investingCom from "@/assets/featured/investingCom.svg";
import techCrunch from "@/assets/featured/techCrunch.svg";
import raritySniper from "@/assets/featured/raritySniper.svg";

interface HeroProps {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
}

const titleDefault = "Convert physical assets into digital tokens";
const subtitleDefault =
  "One-stop solution for the tokenization of your company’s real-world assets (RWAs). The ELOOP Network enables you to transform machines into public investment opportunities, ranging from shared mobility vehicles to solar power plants, and allows you to boost customer engagement and loyalty.";

export default function Hero({
  title = titleDefault,
  subtitle = subtitleDefault,
  actions,
}: HeroProps) {
  return (
    <section className="flex flex-col items-center justify-between h-[calc(100vh-8rem)] px-4 mb-20 bg-gray-700 md:mb-40 bg-[url('../assets/tesla.webp')] bg-cover bg-no-repeat bg-center md:rounded-3xl">
      <div className="h-16" />

      <div>
        <h1 className="max-w-4xl mb-4 text-4xl font-bold text-center text-white uppercase lg:text-6xl">
          {title}
        </h1>
        <h3 className="max-w-4xl mb-10 text-center text-white">{subtitle}</h3>
        {!actions ? (
          <div className="flex flex-col items-center justify-center w-full gap-4 md:flex-row">
            <a href="#contact">
              <Button onClick={() => {}} className="px-8" variant={"secondary"}>
                Get in touch
              </Button>
            </a>
            <Button
              onClick={() =>
                window.open("/ELOOP-NETWORK-WHITEPAPER.pdf", "_blank")
              }
              variant={"link"}
              className="text-white"
            >
              Read whitepaper
            </Button>
          </div>
        ) : null}
      </div>
      <div className="flex gap-10">
        <img
          className="cursor-pointer"
          onClick={() => {
            window.open(
              "https://techcrunch.com/2023/07/10/web3-enterprise-adoption/",
              "_blank"
            );
          }}
          src={techCrunch}
          alt="techCrunch"
        />
        <img
          className="cursor-pointer"
          onClick={() => {
            window.open(
              "https://raritysniper.com/news/eloop-has-tokenized-a-tesla-fleet-offers-profit-sharing/",
              "_blank"
            );
          }}
          src={raritySniper}
          alt="raritySniper"
        />
        <img
          className="cursor-pointer"
          onClick={() => {
            window.open(
              "https://cointelegraph.com/news/100-teslas-democratize-and-decentralize-web3-ride-sharing",
              "_blank"
            );
          }}
          src={coinTelegraph}
          alt="coinTelegraph"
        />
        <img
          className="cursor-pointer"
          onClick={() => {
            window.open(
              "https://www.investing.com/news/cryptocurrency-news/100-tokenized-teslas-democratize-and-decentralize-web3-ride-sharing-3114197",
              "_blank"
            );
          }}
          src={investingCom}
          alt="investingCom"
        />
      </div>
    </section>
  );
}
